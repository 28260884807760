import { Container, Title, Text, Divider, Grid, Image } from "@mantine/core"
import icon1 from '../SVG/icon1.svg'
import icon2 from '../SVG/icon2.svg'
import icon3 from '../SVG/icon3.svg'
import PersonalHeadline from "./personalheadline"

export default function TitleSection() {
    return (
        <Container size="md">
            <PersonalHeadline>Introduction</PersonalHeadline>
            <Grid>
                <Grid.Col xs={8}>
                    <Text><Text weight={700}>Lorem ipsum sapientem ne</Text> eque dolor erat,eros solet invidunt duo Quisque aliquid leo. Pretium patrioque sociis eu nihil Cum enim ad, ipsum alii vidisse justo id. Option porttitor diam voluptua. Cu Eam augue dolor dolores quis, Nam aliquando elitr Etiam consetetur. Fringilla lucilius mel adipiscing rebum. Sit nulla Integer ad volumus, dicta scriptorem viderer lobortis est Utinam, enim commune corrumpit Aenean erat tellus. Metus sed amet dolore justo, gubergren sed. </Text>
                </Grid.Col>
                <Grid.Col xs={4}>
                    <div style={{ width: 140, marginLeft: 'auto', marginRight: 'auto' }}>
                        <Image
                            radius="md"
                            src={icon1}
                            alt="Random unsplash image"
                        />
                    </div>
                </Grid.Col>
                
                <Grid.Col><div style={{ height: 55 }}></div></Grid.Col>

                <Grid.Col xs={4}>
                    <div style={{ width: 140, marginLeft: 'auto', marginRight: 'auto' }}>
                        <Image
                            radius="md"
                            src={icon2}
                            alt="Random unsplash image"
                        />
                    </div>
                </Grid.Col>
                <Grid.Col xs={8}>
                    <Text ><Text  weight={700}>Lorem ipsum sapientem ne</Text> eque dolor erat,eros solet invidunt duo Quisque aliquid leo. Pretium patrioque sociis eu nihil Cum enim ad, ipsum alii vidisse justo id. Option porttitor diam voluptua. Cu Eam augue dolor dolores quis, Nam aliquando elitr Etiam consetetur. Fringilla lucilius mel adipiscing rebum. Sit nulla Integer ad volumus, dicta scriptorem viderer lobortis est Utinam, enim commune corrumpit Aenean erat tellus. Metus sed amet dolore justo, gubergren sed. </Text>
                </Grid.Col>
                <Grid.Col><div style={{ height: 55 }}></div></Grid.Col>
                <Grid.Col xs={8}>
                    <Text><Text weight={700}>Lorem ipsum sapientem ne</Text> eque dolor erat,eros solet invidunt duo Quisque aliquid leo. Pretium patrioque sociis eu nihil Cum enim ad, ipsum alii vidisse justo id. Option porttitor diam voluptua. Cu Eam augue dolor dolores quis, Nam aliquando elitr Etiam consetetur. Fringilla lucilius mel adipiscing rebum. Sit nulla Integer ad volumus, dicta scriptorem viderer lobortis est Utinam, enim commune corrumpit Aenean erat tellus. Metus sed amet dolore justo, gubergren sed. </Text>
                </Grid.Col>
                <Grid.Col xs={4}>
                    <div style={{ width: 140, marginLeft: 'auto', marginRight: 'auto' }}>
                        <Image
                            radius="md"
                            src={icon3}
                            alt="Random unsplash image"
                        />
                    </div>
                </Grid.Col>
            </Grid>

            <div style={{ height: 35 }}></div>
        </Container>
    )
}