
import { Timeline, Text, Container, Grid, Image, Divider, List } from '@mantine/core';
import { GitBranch, Atom } from 'tabler-icons-react';
import konrad from '../images/img_Konrad_2.jpg'

export default function CV() {
    return (
        <Container size="md">
            <div style={{ height: "20px" }}></div>
            <h1>Curriculum Vitae</h1>
            <br/>
            <Grid>
                <Grid.Col xs={8}>
                    <Grid>
                        <Grid.Col xs={8}>
                            <Text size="xl">Konrad Bendzuck</Text>
                            <Text size="lg">Process Engineer/ Developer</Text>
                            <br />
                            {/*<Space w="xl"/>*/}
                            <Text>Motivated engineer with experience in organization of a pan-European project in battery process engineering, as well as development of extensive software for dynamic cost analysis. Passionate about building programs in data automatization to simplify work processes and improve time efficiency.</Text>
                        </Grid.Col>
                        <Grid.Col xs={4}>
                            <Image
                                radius="md"
                                src={konrad}
                                alt="Random unsplash image"
                            />
                        </Grid.Col>
                        <Grid.Col>
                            <Divider />
                            <br />
                            <Text size="lg" transform="uppercase">Work experience</Text>
                            <br />

                            <Timeline style={{marginLeft:"20px"}} color="orange" active={3} bulletSize={24} lineWidth={2}>
                                <Timeline.Item lineVariant="dashed" bullet={<Atom size={24} />} title="Scientific Assistant">
                                    <Text color="dimmed">Insitute for particel technology, TU Brunswick</Text>
                                    <List size="xs">
                                        <List.Item>
                                            Establishment of a network of pilot lines of lithium battery production in Europe: organization of workshops, creation of expert groups, responsible for communication with project partners, external stakeholders and the EU Commission
                                        </List.Item>
                                        <List.Item>
                                            Development and programming of a dynamic cost model within the German research cluster ProZell
                                        </List.Item>
                                    </List>

                                    <Text color="dimmed" size="xs" mt={4}>Brunswick, Germany <br />
                                        Feb 2019 - today</Text>
                                </Timeline.Item>

                                <Timeline.Item bullet={<Atom size={24} />} title="Intern">
                                    <Text color="dimmed">German-Aerospace-Center</Text>
                                    <List size="xs">
                                        <List.Item>
                                            Development of a program for the calculation and displaying of the additional weight in aviation by different joining methods.                                        </List.Item>

                                    </List>

                                    <Text color="dimmed" size="xs" mt={4}>Brunswick, Germany <br />
                                        Sep 2016 – Nov 2016</Text>
                                </Timeline.Item>

                                <Timeline.Item bullet={<Atom size={24} />} title="Tutor technical mechanics">
                                    <Text color="dimmed">TU Brunswick</Text>
                                    <List size="xs">
                                        <List.Item>
                                            Preparation and implementation of tutorials for bachelor students                                        </List.Item>
                                        <List.Item>
                                            Preparation and implementation of intensive courses for more than 100 students                                        </List.Item>
                                        <List.Item>
                                            Private tutoring                                      </List.Item>
                                    </List>

                                    <Text color="dimmed" size="xs" mt={4}>Brunswick, Germany <br />
                                        Feb 2019 - today</Text>
                                </Timeline.Item>

                            </Timeline>
                            <br />
                            <Divider />


                            <br />
                            <Text size="lg" transform="uppercase">Education</Text>
                            <br />

                            <Timeline style={{marginLeft:"20px"}} color="orange" active={3} bulletSize={24} lineWidth={2}>
                                <Timeline.Item bullet={<Atom size={24} />} title="MSc Automotive Engineering">
                                    <Text color="dimmed">TU Brunswick</Text>
                                    <List size="xs">
                                        <List.Item>
                                            Master thesis: Methodology for automatic variation of automotive components in SFE Concept (+ Python)                                        </List.Item>
                                        <List.Item>
                                            Study research project: designing an accumulator container made of carbon fiber composites                                        </List.Item>
                                    </List>

                                    <Text color="dimmed" size="xs" mt={4}>Brunswick, Germany <br />
                                        Aug 2017- Nov 2019</Text>
                                </Timeline.Item>

                                <Timeline.Item bullet={<Atom size={24} />} title="BSc Engineering">
                                    <Text color="dimmed">TU Brunswick</Text>
                                    <List size="xs">
                                        <List.Item>
                                            Specialised in Automotive Engineering                                       </List.Item>
                                        <List.Item>
                                            Bachelor thesis: Mechanical characterization of porcine uterine tissue                                       </List.Item>

                                    </List>

                                    <Text color="dimmed" size="xs" mt={4}>Brunswick, Germany <br />
                                        Aug 2013- Nov 2016</Text>
                                </Timeline.Item>


                            </Timeline>
                            <br />
                            <Divider />
                            <br />
                            <Text size="lg" transform="uppercase">OTHER PROJECTS</Text>
                            <br />
                            <List size="xs" withPadding>
                                <List.Item>
                                    Member of the Lions Racing Team (Formula Student Team of the TU Braunschweig) in the 2019 season, responsible for the design and construction of an accumulator container made of carbon fiber composites.                                  </List.Item>
                                <List.Item>
                                    Engaged as a Juleica-certified teamer for multiple youth recreations of the sports club SSC Germania                                      </List.Item>

                            </List>

                        </Grid.Col>
                    </Grid>
                </Grid.Col>

                <Grid.Col xs={4}
                    sx={(theme) => ({
                        backgroundColor: theme.colors.orange[0],
                        '&:hover': {
                            backgroundColor: theme.colors.orange[1],
                        },
                        paddingLeft: "20px",
                        paddingRight: "20px"
                    })}
                >
                    <Text size="lg" transform="uppercase">Contacts</Text>
                    <Divider />
                    <Text weight={600}>E-Mail</Text>
                    <Text>k.bendzuck@web.de</Text>
                    <br/>
                    <Text weight={600}>Address</Text>
                    <Text>Braunschweig</Text>
                    <Text>Germany</Text>
                    <br/>
                    <Text weight={600}>Phone</Text>
                    <Text>+4915771757874</Text>
                    <br/>
                    <Text weight={600}>Date of birth</Text>
                    <Text>29th of March 1994</Text>
                    <br/>
                    <Text weight={600}>Nationilty</Text>
                    <Text>German</Text>
                    <br />
                    <br/>
                    <br/>
                    <Text size="lg" transform="uppercase">Skills</Text>
                    <Divider />
                    <List >
                        <List.Item>Problem solving skills</List.Item>
                        <List.Item>Good learner</List.Item>
                        <List.Item>Good Teacher</List.Item>
                        <List.Item>Organisation- and time management</List.Item>
                        <List.Item>Analytical and critical thinking</List.Item>
                        <List.Item>Project management</List.Item>
                    </List>
                    <br/>
                    <br/>
                    <br/>

                    <Text size="lg" transform="uppercase">Languages</Text>
                    <Divider />
                    <Text weight={600}>German</Text>
                    <Text>Native</Text>
                    <br/>
                    <Text weight={600}>English</Text>
                    <Text>Proficient</Text>
                    <br/>
                    <Text weight={600}>French</Text>
                    <Text>Elementary</Text>
                    <br/>
                    <br/>
                    <br/>


                    <Text size="lg" transform="uppercase">Software</Text>
                    <Divider />
                    <List >
                        <List.Item>Office (Word, PowerPoint, Excel)</List.Item>
                        <List.Item>Python</List.Item>
                        <List.Item>JavaScript</List.Item>
                        <List.Item>React</List.Item>
                        <List.Item>HTML/CSS</List.Item>
                        <List.Item>Docker</List.Item>
                        <List.Item>Siemens NX12 (certifies basic training)</List.Item>
                    </List>
                    



                </Grid.Col>
            </Grid>



        </Container>
    );
}