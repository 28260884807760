import React from 'react';
import { Grid, Skeleton, Container, Title } from '@mantine/core';
import ArticleCardImage from './cardsimple';
import PersonalHeadline from './personalheadline';

export function GridAsymmetrical() {
    return (
        <Container my="md">
            <PersonalHeadline>Feel free to Explore</PersonalHeadline>
            <Grid>

                <Grid.Col xs={4}>
                    <ArticleCardImage
                        image="https://images.unsplash.com/photo-1508193638397-1c4234db14d8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80"
                        title="Best forests to visit in North America"
                        category="nature"
                    />
                </Grid.Col>
                <Grid.Col xs={8}>
                    <ArticleCardImage
                        image='https://images.unsplash.com/photo-1648290023964-9e8fca75b234?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80'
                        title="Find the best vintage cars in town"
                        category="Automobiles"
                    />
                </Grid.Col>
                <Grid.Col xs={8}>
                    <ArticleCardImage
                        image='https://images.unsplash.com/photo-1461749280684-dccba630e2f6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80'
                        title="Get in touch with local programmers"
                        category="Technology"
                    />
                </Grid.Col>
                <Grid.Col xs={4}>
                    <ArticleCardImage
                        image='https://images.unsplash.com/photo-1519821172144-4f87d85de2a1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=684&q=80'
                        title="A walk in the forest calms everyone of us down"
                        category="Relaxation"
                    />
                </Grid.Col>
                <Grid.Col xs={4}>
                    <ArticleCardImage
                        image='https://images.unsplash.com/photo-1511920170033-f8396924c348?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80'
                        title="Drink the finest coffee"
                        category="Beverages"
                    />
                </Grid.Col>
                <Grid.Col xs={8}>
                    <ArticleCardImage
                        image='https://images.unsplash.com/photo-1497935586351-b67a49e012bf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjB8fGNvZmZlZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60'
                        title="Drink the finest Espresso too"
                        category="Beverages"
                    />
                </Grid.Col>
                <Grid.Col xs={12}>
                    <ArticleCardImage
                        image='https://images.unsplash.com/photo-1646041293273-788e7543dd3d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80'
                        title="Look at this desk"
                        category="Working"
                    />
                </Grid.Col>

            </Grid>
            <div style={{ height: 35 }}></div>
        </Container>
    );
}