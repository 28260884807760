// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCrF6e_f0eCzxdD_pWGXOFtOnj8LtWXOrM",
  authDomain: "kb-personal-webpage.firebaseapp.com",
  projectId: "kb-personal-webpage",
  storageBucket: "kb-personal-webpage.appspot.com",
  messagingSenderId: "569453641832",
  appId: "1:569453641832:web:541b9f680f3097a27e3bdd"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

//storage
/*
const storage = getStorage();
const sorageRef = ref(storage);
const imagesRef = ref(storage, 'images')
const bildRef = ref(storage, 'images/Bild1.png')
*/

export default getFirestore();