import React, {useRef} from 'react'
import '.././App.css';
import fokker from '../images/fokker.png'
import { useWindowScroll, useIntersection } from '@mantine/hooks';

export default function Parallax() {
    const [scroll, scrollTo] = useWindowScroll();
    const containerRef = useRef();
    const [plane, observer] = useIntersection({
        root: containerRef.current,
        threshold: 1
      });

    //const plane = useRef();

    return (

        <div id='parallax_box' ref={containerRef}> 

                {/*<div style={{transform: `translate(${-(3800-scroll.y)/8}px,${(5000-scroll.y)/12}px)`}} ref={ref} id="Flugzeug_box"/>*/}
                <img src={fokker} style={{ backgroundColor: observer?.isIntersecting ? "black":"blue",
                transform: `translate(${-(3800-scroll.y)/8}px,${(3400-scroll.y)/12}px)`
            }} id="fokker"></img>

                <h1 className='parallax_text' >Parallax Heading</h1>
                {observer?.isIntersecting ? <p  className='parallax_text'>in scope</p> : <p className='parallax_text'>not in scope</p>}
                <p className='parallax_text'> Scroll position x: {scroll.x}, y: {scroll.y}</p>
                <p className='parallax_text' ref={plane}>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                </p>

        </div>

    )
}