import React from 'react'
import { Blockquote, Container, Grid, Group, Input } from "@mantine/core"
import { setDoc, doc, addDoc, collection, onSnapshot, storage } from "firebase/firestore"
import { Button, Box, TextInput, Checkbox } from "@mantine/core"
import { useState, useEffect } from 'react'
import PersonalHeadline from "./personalheadline"
import { useForm } from '@mantine/form';
import '@firebase/storage';

import db from '.././firebase'

export default function Quotes() {
    //overwrite existinc doc or creating new


    const handleSetDoc = async () => {
        const docRef = doc(db, "Nutzer", "id001")
        const payload = { quote: "lorem Ipsum", name: "Konrad Bendzuck" }
        await setDoc(docRef, payload);
    }

    const handleAddDoc = async () => {
        const collectionRef = collection(db, "Quotes");
        const payload = { quote: "lorem Ipsum", name: "Konrad Bendzuck" }
        await addDoc(collectionRef, payload)
    }

    const addQuote = async () => {
        const collectionRef = collection(db, "Quotes");
        const payload = { quote: newquote.values.quote, name: newquote.values.author }
        await addDoc(collectionRef, payload)
    }

    const [quotes, setQuotes] = useState(null)
    useEffect(() =>
        onSnapshot(collection(db, "Quotes"), (snapshot) => {
            setQuotes(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })))
        }),
        []
    );

    const newquote = useForm({
        initialValues: {
            quote: '',
            author: '',
        }
    })

    return (
        <Container size="md">
            <PersonalHeadline>Famous Quotes</PersonalHeadline>
            <Grid>
                <Grid.Col xs={4}>
                    <Box sx={{ maxWidth: 300 }} mx="auto">
                        <newquote onSubmit={newquote.onSubmit((values) => console.log(values))}>
                            <TextInput
                                label="Quote"
                                placeholder="lorem ipsum"
                                {...newquote.getInputProps('quote')}
                            />
                            <TextInput
                                label="Author"
                                placeholder="Konrad"
                                {...newquote.getInputProps('author')}
                            />

                            <Group position="right" mt="md">
                                <Button type="submit" onClick={addQuote}>Submit</Button>
                            </Group>
                        </newquote>
                    </Box>

                </Grid.Col>
                <Grid.Col xs={8}></Grid.Col>
                {quotes !== null &&
                    quotes.map(quote =>
                        <Grid.Col xs={4} key={quote.id}>
                            <Blockquote cite={'– '+quote.name}>
                                {quote.quote}
                            </Blockquote>
                        </Grid.Col>
                    )}


            </Grid>

        </Container>
    )
}
