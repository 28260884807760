import { Container, Divider } from '@mantine/core';
import './App.css';
import Footer from './components/footer';
import { GridAsymmetrical } from './components/gridContent';
import Header from './components/header';
import Quotes from './components/quotes';
import TableOfContentsFloating from './components/sidemenu';
import TitleSection from './components/titlesection';
import { useViewportSize } from '@mantine/hooks';
import CV from './components/cv';
import LoginScreen from './components/loginscreen';


import db from './firebase'
import { useEffect, useRef, useState } from 'react'
import { collection, onSnapshot } from 'firebase/firestore';
import Parallax from './components/parallax_scrolling';



function App() {
  const introduction = useRef()

  const { height, width } = useViewportSize();

  function handleBackClick(ref) {
    introduction.current.scrollIntoView({ behavior: 'smooth' })
  }

  const [user, setUser] = useState(null)
  useEffect(() =>
    onSnapshot(collection(db, "Nutzer"), (snapshot) => {
      setUser(snapshot.docs.map(doc => ({...doc.data(), id: doc.id})))
    }),
    []
  );

  return (
    <div>
      {/*<LoginScreen/>*/}
      <Header onStart={() => handleBackClick("introduction")} />

      <div id="sidemnudiv">
        {width > 1400 &&
          <TableOfContentsFloating onClick={(ref) => handleBackClick(ref)} />
        }
      </div>

      <div ref={introduction} id="introduction">
        <TitleSection />
      </div>

      <Container size="md" >
        <Divider />
      </Container >   

      <div id="explore">
        <GridAsymmetrical />
        {/* 
        {user!==null && 
        <Container my="md">
          {user.map((user,index) => 
          <div key={user.id}>
          <p style={{fontWeight:"bold"}} >{user.firstname} {user.secondname}</p>
          <p>Type: {user.type}</p>
          <p>Age: {user.age}</p>
          </div>
          )}
        
        </Container>}
        */}
      </div>

      <Container size="md">
        <Divider />
      </Container >

      <div id="parallax">
        <Parallax/>
      </div>


      <Container size="md">
        <Divider />
      </Container >

      <div id="quotes">
        <Quotes />
      </div>

      <Container size="md">
        <Divider />
      </Container >

      <div id="cv">
        <CV />
      </div>

      <div id="footer">
        <Footer />
      </div>
    </div>

  );
}

export default App;

/*
Two way to scroll smoothly:

  const startRef = useRef()

  function handleBackClick() {
    startRef.current.scrollIntoView({behavior : 'smooth'})
  }

<Container size="md" ref={startRef}>

*/