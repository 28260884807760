import React from 'react';
import {
    Paper,
    createStyles,
    TextInput,
    PasswordInput,
    Checkbox,
    Button,
    Title,
    Text,
    Anchor,
    Image
} from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';

const backgrounds = [
    'https://images.unsplash.com/photo-1503942142281-94af0aded523?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=735&q=80',
    'https://images.unsplash.com/photo-1565214975484-3cfa9e56f914?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=627&q=80',
    'https://images.unsplash.com/photo-1565643355044-000fbad87cc5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=627&q=80',
    'https://images.unsplash.com/photo-1583030225577-329fe6cc80d6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=765&q=80'
]
const background = backgrounds[Math.floor(Math.random() * backgrounds.length)];


const useStyles = createStyles((theme) => ({
    wrapper: {
        height: "100vh",
        width: "100%",
        display: "flex",
    },

    image: {
        width: "50%",
        height: "100vh"
    },

    paperwrapper: {
        width: "45%"
    },
    paperwrapper2: {
        width: "90%"
    },

    form: {
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        borderRight: `0px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[3]
            }`,
        height: "100vh",
        width: "50%",
        minHeight: 900,
        paddingTop: 80,

        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            maxWidth: '100%',
        },
    },

    title: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },

    logo: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        width: 120,
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}));

export default function LoginScreen() {
    const { classes } = useStyles();
    const { height, width } = useViewportSize();
    return (
        <div className={classes.wrapper}>

            <Paper className={classes.form} radius={0} p={30}>
                <div className={width > 1400 ? classes.paperwrapper : classes.paperwrapper2}>
                    <Title order={2} className={classes.title} align="center" mt="md" mb={50}>
                        Welcome back to KB Engineering!
                    </Title>

                    <TextInput label="Email address" placeholder="hello@gmail.com" size="md" />
                    <PasswordInput label="Password" placeholder="Your password" mt="md" size="md" />
                    <Checkbox label="Keep me logged in" mt="xl" size="md" />
                    <Button color="indigo" fullWidth mt="xl" size="md" radius="xs">
                        Login
                    </Button>

                    <Text align="center" mt="md" color="indigo">
                        Don&apos;t have an account?{' '}
                        <Anchor
                            href="https://www.w3schools.com/"
                            target="_blank"
                            weight={700}
                            //onClick={(event) => event.preventDefault()}
                        >
                            Register
                        </Anchor>
                    </Text>
                </div>
            </Paper>
            <div className={classes.image}>
                <Image
                    src={background}
                    alt="Random unsplash image"
                    height={height}
                />
            </div>
        </div>
    );
}