import React, { useState } from 'react';
import { createStyles, Overlay, Container, Title, Button, Text, Group, Modal } from '@mantine/core';
import LoginScreen from './loginscreen';

const useStyles = createStyles((theme) => ({
  hero: {
    position: 'relative',
    backgroundImage:
      'url(https://images.unsplash.com/photo-1542273917363-3b1817f69a2d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1174&q=80)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },

  container: {
    height: "100vh",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    paddingBottom: theme.spacing.xl * 0,
    zIndex: 1,
    position: 'relative',

    [theme.fn.smallerThan('sm')]: {
      height: 500,
      paddingBottom: theme.spacing.xl * 3,
    },
  },

  spacer: {
    height: theme.spacing.xl * 6
  },

  title: {
    color: theme.white,
    fontSize: 60,
    fontWeight: 900,
    lineHeight: 1.1,

    [theme.fn.smallerThan('sm')]: {
      fontSize: 40,
      lineHeight: 1.2,
    },

    [theme.fn.smallerThan('xs')]: {
      fontSize: 28,
      lineHeight: 1.3,
    },
  },

  description: {
    color: theme.white,
    maxWidth: 600,

    [theme.fn.smallerThan('sm')]: {
      maxWidth: '100%',
      fontSize: theme.fontSizes.sm,
    },
  },

  control: {
    marginTop: theme.spacing.xl * 1.5,
    minWidth: 170,

    [theme.fn.smallerThan('sm')]: {
      width: '100%',
    },
  },
}));

export default function Header(props) {
  const { classes } = useStyles();
  const [opened, setOpened] = useState(false);
  return (
    <div className={classes.hero}>
      <Overlay
        gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .65) 40%)"
        opacity={1}
        zIndex={0}
      />
      <Modal
        size="75%"
        opened={opened}
        onClose={() => setOpened(false)}
      //title="Introduce yourself!"
      >
        <LoginScreen />
      </Modal>
      <Container className={classes.container}>
        <Title className={classes.title}>KB Engineering software solution</Title>
        <Text className={classes.description} size="xl" mt="xl">
          Coupling complex calculations in engineering with intuitive and accessible UIs. A unique approach to the fundamental problem of sharing solutions.
        </Text>
        <Group mt={30}>
          <Button variant="gradient" gradient={{ from: 'dark', to: 'gray' }} size="lg" radius="md" className={classes.control} onClick={props.onStart}>
            Get started
          </Button>
          <Button variant="white" color="dark" size="lg" radius="md" onClick={() => setOpened(true)} className={classes.control}>
            About
          </Button>
        </Group>
        {/* Ursprünglich ohne spacer, in Container war     paddingBottom: theme.spacing.xl * 6 */}
        <div className={classes.spacer} />
      </Container>
    </div>
  );
}