import { Title } from "@mantine/core";


export default function PersonalHeadline(props){
    return(
        <>
        <div style={{ height: 35 }}></div>
        <Title order={1}>{props.children}</Title>
        <div style={{ height: 35 }}></div>
        </>
    )
}